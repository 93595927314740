// src/App.js

import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { SiteModeProvider } from './contexts/SiteModeContext';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import About from './pages/about/About';
import Login from './pages/login/Login';
import Signup from './pages/signup/Signup';
import VideoManager from './pages/VideoManager';
import LegalInformation from './pages/legal/LegalInformation';
import { initGA, logPageView } from './analytics'; // Importer les fonctions d'analytics

function App() {
  useEffect(() => {
    initGA(); // Initialiser Google Analytics
  }, []);

  const [isLoggedIn, setIsLoggedIn] = useState(() => {
    return localStorage.getItem('isLoggedIn') === 'true';
  });
  const [isAdmin, setIsAdmin] = useState(false);

  const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3000';

  useEffect(() => {
    checkSession();
    checkAdminSession();
  }, []);

  const checkSession = async () => {
    try {
      const response = await fetch(`${API_URL}/api/auth/check-session`, {
        method: 'GET',
        credentials: 'include',
      });

      const data = await response.json();
      setIsLoggedIn(data.loggedIn);
    } catch (error) {
      console.error('Erreur lors de la vérification de la session:', error);
    }
  };

  const checkAdminSession = async () => {
    try {
      const response = await fetch(`${API_URL}/api/auth/check-admin-session`, {
        method: 'GET',
        credentials: 'include',
      });

      const data = await response.json();
      setIsAdmin(data.isAdmin);
    } catch (error) {
      console.error('Erreur lors de la vérification de la session administrateur:', error);
    }
  };

  const handleLogout = () => {
    setIsLoggedIn(false);
    setIsAdmin(false);
    localStorage.removeItem('isLoggedIn');
  };

  const handleLogin = () => {
    setIsLoggedIn(true);
    localStorage.setItem('isLoggedIn', 'true');
    checkAdminSession();
  };

  // Définition du composant SignupWrapper pour passer les props correctement
  const SignupWrapper = () => {
    return <Signup onLogin={handleLogin} />;
  };

  return (
    <SiteModeProvider>
      <div className="app-container">
        <Router>
          <Header isLoggedIn={isLoggedIn} isAdmin={isAdmin} onLogout={handleLogout} />
          <RouteChangeTracker /> {/* Composant pour suivre les changements de route */}
          <main className="main-content">
            <Routes>
              <Route path="/" element={<Home isLoggedIn={isLoggedIn} />} />
              <Route path="/login" element={<Login onLogin={handleLogin} />} />
              <Route path="/signup" element={<SignupWrapper />} />
              <Route path="/about" element={<About />} />
              {isAdmin && <Route path="/admin/videos" element={<VideoManager />} />}
              <Route path="/legal-information" element={<LegalInformation />} />
            </Routes>
          </main>
          <Footer />
        </Router>
      </div>
    </SiteModeProvider>
  );
}

// Nouveau composant pour suivre les changements de route
function RouteChangeTracker() {
  const location = useLocation();

  useEffect(() => {
    logPageView();
  }, [location]);

  return null;
}

export default App;
