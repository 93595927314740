// src/components/Footer.js
import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';

function Footer() {
  const currentYear = new Date().getFullYear();
  return (
    <footer>
      <p>
        &copy; {currentYear} confessia.fr. Tous droits réservés.
        <Link to="/legal-information">Mentions Légales</Link>      
      </p>
    </footer>
  );
}

export default Footer;
