// src/pages/signup/Signup.js

import React, { useState } from 'react';
import './Signup.css';
import { useNavigate } from 'react-router-dom';

function Signup({ onLogin }) {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    ageConfirmed: false,
  });
  const [errors, setErrors] = useState([]);
  const navigate = useNavigate();

  const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3000';

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Vérifier si onLogin est bien une fonction
    if (typeof onLogin !== 'function') {
      console.error('onLogin n\'est pas une fonction ou est indéfini.');
      return;
    }

    try {
      const response = await fetch(`${API_URL}/api/auth/register`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include', // Pour inclure les cookies de session
        body: JSON.stringify(formData),
      });

      // Vérifie que le type de la réponse est bien du JSON
      const contentType = response.headers.get('content-type');
      if (contentType && contentType.includes('application/json')) {
        const data = await response.json();
        if (response.ok) {
          alert('Inscription réussie!');
          onLogin(); // Mettre à jour l'état de connexion global
          navigate('/'); // Redirection vers la page d'accueil
        } else {
          setErrors(data.errors || [data.message || "Erreur lors de l'inscription"]);
        }
      } else {
        setErrors(['Réponse non valide du serveur.']);
      }
    } catch (error) {
      console.error('Erreur:', error);
      setErrors(["Erreur de connexion au serveur."]);
    }
  };

  return (
    <div className="main-content">
      <div className="signup-container">
        <h2>Créer un compte</h2>
        <form onSubmit={handleSubmit}>
          {errors.length > 0 && (
            <div className="error-messages">
              {errors.map((error, index) => (
                <p key={index}>{error.msg || error}</p>
              ))}
            </div>
          )}
          <label htmlFor="email">Email</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
          <label htmlFor="password">Mot de passe</label>
          <input
            type="password"
            id="password"
            name="password"
            value={formData.password}
            onChange={handleChange}
            required
          />
          <label className="age-confirmation">
            <input
              type="checkbox"
              id="ageConfirmed"
              name="ageConfirmed"
              checked={formData.ageConfirmed}
              onChange={handleChange}
              required
            />
            <span>Je confirme avoir plus de 18 ans</span>
          </label>
          <button type="submit">S'inscrire</button>
        </form>
        <p>
          Vous avez déjà un compte? <a href="/login">Se connecter</a>
        </p>
      </div>
    </div>
  );
}

export default Signup;
