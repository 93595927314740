// src/pages/legal/LegalInformation.js
import React from 'react';
import './LegalInformation.css';

function LegalInformation() {
    return (
        <div className="legal-information-container">
            <h1>Mentions légales</h1>
            <h2>Éditeur du site</h2>
            <p>Le site Confessia est édité par Nefset.</p>

            <h2>Hébergement</h2>
            <p>L’hébergement du site est assuré par o2switch, dont le siège social est situé à l’adresse suivante :</p>
            <p>Chemin des Pardiaux, 63000 Clermont-Ferrand, France.</p>
            <p>Numéro de téléphone : 04 44 44 60 40</p>

            <h2>Contact</h2>
            <p>Pour toute question ou réclamation, vous pouvez nous contacter par email à l’adresse suivante :</p>
            <p>contact@confessia.fr</p>

            <h2>Directeur de la publication</h2>
            <p>Le directeur de la publication est Nefset.</p>
        </div>
    );
}

export default LegalInformation;
