// src/contexts/SiteModeContext.js
import React, { createContext, useState, useEffect } from 'react';

export const SiteModeContext = createContext();

export const SiteModeProvider = ({ children }) => {
  const [siteMode, setSiteMode] = useState('mode1'); // Valeur par défaut

  useEffect(() => {
    // Récupérer le mode depuis le serveur
    const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3000';
    fetch(`${API_URL}/api/site-mode`, {
      credentials: 'include'
    })
      .then(res => res.json())
      .then(data => {
        setSiteMode(data.mode);
      })
      .catch(err => {
        console.error('Erreur lors de la récupération du mode:', err);
        // En cas d'erreur, on laisse mode1 par défaut
      });
  }, []);

  return (
    <SiteModeContext.Provider value={{ siteMode, setSiteMode }}>
      {children}
    </SiteModeContext.Provider>
  );
};
