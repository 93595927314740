import React from 'react';
import './About.css';

const About = () => {
  return (
    <div className="about-container">
      <h1>Bienvenue sur Confessia <span role="img" aria-label="fête">🎉</span></h1>
      <p>
        Le but de cette plateforme ? C'est simple : nous permettre (à ceux qui le souhaitent) de partager anonymement des choses que l'on a faites 
        ou que nous aimerions faire... sans jugement ! <span role="img" aria-label="souriant">😌</span>
      </p>
      <p>
        Nous trions les confessions ! <span role="img" aria-label="yeux ouverts">👀</span> Nous avons choisi de ne pas diffuser celles qui comportent 
        de la violence ou qui ne respectent pas nos valeurs.
        Nous acceptons de diffuser les confessions intimes, tant qu'elles ne sont pas dans l'exagération. (Restons dans les limites du raisonnable <span role="img" aria-label="clin d'œil">😉</span>).
      </p>
      <p>
        Confessia, c'est avant tout un espace de divertissement, où certains dévoilent des choses inavouées tandis que d'autres découvrent, à travers les vidéos <span role="img" aria-label="clap cinéma">🎬</span>, des expériences qu'ils ne feront jamais. 
        Vous y trouverez peut-être même des récits et des situations que vous n'auriez jamais imaginées !

        Nous nous forçons tout de même à rester très soft, autant que possible, tant que l'histoire n'exige pas plus (L'histoire avant tout! <span role="img" aria-label="souriant">😌</span>).
      </p>
      <p>
        Et si malgré nos tris, vous avez été offensé par quelque chose, n'hésitez pas à nous exprimer votre mécontentement afin que nous puissions essayer de nous corriger.
        Vos retours nous aident à nous améliorer <span role="img" aria-label="poignée de main">🤝</span>. 
        Ensemble, faisons de Confessia un espace accueillant pour tout le monde <span role="img" aria-label="étincelle">✨</span>.
      </p>
    </div>
  );
};

export default About;
