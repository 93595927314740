// src/pages/login/Login.js

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Login.css'; // Assurez-vous que le chemin est correct

function Login({ onLogin }) {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    stayConnected: true,
  });

  const [errors, setErrors] = useState([]);
  const navigate = useNavigate();

  // Gestion du changement des champs du formulaire
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  // Gestion de la soumission du formulaire
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Réinitialiser les erreurs
    setErrors([]);

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/auth/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include', // Inclure les cookies dans la requête
        body: JSON.stringify({
          email: formData.email,
          password: formData.password,
        }),
      });

      // Vérifier si la réponse est en JSON
      const contentType = response.headers.get('content-type');
      let data = {};
      if (contentType && contentType.includes('application/json')) {
        data = await response.json();
      } else {
        const text = await response.text();
        console.log('Réponse du serveur (texte brut) :', text);
        data.message = text;
      }

      if (response.ok) {
        console.log('Connexion réussie:', data);
        onLogin(); // Mise à jour de l'état de connexion
        navigate('/');
      } else {
        console.error('Erreur lors de la connexion:', data.message);
        setErrors([data.message || 'Erreur lors de la connexion']);
      }
    } catch (error) {
      console.error('Erreur:', error);
      setErrors(['Erreur de connexion au serveur.']);
    }
  };

  return (
    <div className="login-container">
      <h2>Se connecter</h2>
      <form onSubmit={handleSubmit}>
        {errors.length > 0 && (
          <div className="error-messages">
            {errors.map((error, index) => (
              <p key={index}>{error}</p>
            ))}
          </div>
        )}
        <label htmlFor="email">Email</label>
        <input
          type="email"
          id="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          required
        />
        <label htmlFor="password">Mot de passe</label>
        <input
          type="password"
          id="password"
          name="password"
          value={formData.password}
          onChange={handleChange}
          required
        />
        <label className="stay-connected">
          <input
            type="checkbox"
            id="stayConnected"
            name="stayConnected"
            checked={formData.stayConnected}
            onChange={handleChange}
          />
          <span>Rester connecté</span>
        </label>
        <button type="submit">Se connecter</button>
      </form>
      <p>
        Vous n'avez pas de compte? <a href="/signup">Créer un compte</a>
      </p>
    </div>
  );
}

export default Login;
