// src/analytics.js

import ReactGA from 'react-ga4';

export const initGA = () => {
  ReactGA.initialize('G-38FSZKXQFV'); // Remplacez par votre ID de mesure
};

export const logPageView = () => {
  ReactGA.send('pageview');
};

export const logEvent = (category = '', action = '', label = '') => {
  if (category && action) {
    ReactGA.event({
      category,
      action,
      label,
    });
  }
};
