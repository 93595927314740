// client/src/components/Header.js

import React, { useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './Header.css';
import { SiteModeContext } from '../contexts/SiteModeContext'; // Importer le contexte

function Header({ isLoggedIn, isAdmin, onLogout }) {
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_URL;
  const { siteMode, setSiteMode } = useContext(SiteModeContext);

  const handleLogout = async () => {
    try {
      const response = await fetch(`${apiUrl}/api/auth/logout`, {
        method: 'POST',
        credentials: 'include',
      });

      if (response.ok) {
        localStorage.removeItem('isLoggedIn');
        onLogout();
        navigate('/');
      } else {
        console.error('Erreur lors de la déconnexion');
      }
    } catch (error) {
      console.error('Erreur lors de la déconnexion', error);
    }
  };

  const toggleSiteMode = () => {
    const newMode = siteMode === 'mode1' ? 'mode2' : 'mode1';
    const confirmation = window.confirm(
      `Êtes-vous sûr de vouloir passer en ${newMode === 'mode1' ? 'Mode 1' : 'Mode 2'} ?`
    );
    if (confirmation) {
      setSiteMode(newMode);
    }
  };

  return (
    <header>
      <nav className="navbar">
        <div className="container">
          <Link to="/" className="logo">
            <img src="/logo.png" alt="Logo" className="logo-image" />
          </Link>
          <div className="nav-links">
            <ul>
              {/* À propos (bouton utilisateur => rose #d81b60) */}
              <li>
                <Link to="/about" className="nav-item pink-btn">
                  <div className="nav-icon">
                    <img src="/icons/about.svg" alt="À propos" />
                  </div>
                  <span className="nav-text">À propos</span>
                </Link>
              </li>

              {/* Admin (bleu) */}
              {isAdmin && (
                <>
                  <li>
                    <Link to="/admin/videos" className="nav-item blue-btn">
                      <div className="nav-icon">
                        <img src="/icons/admin.svg" alt="Gestion des vidéos" />
                      </div>
                      <span className="nav-text">Gestion des vidéos</span>
                    </Link>
                  </li>
                  <li>
                    <button onClick={toggleSiteMode} className="nav-item blue-btn">
                      <div className="nav-icon">
                        <img src="/icons/mode.svg" alt="Mode" />
                      </div>
                      <span className="nav-text">
                        Passer en {siteMode === 'mode1' ? 'Mode 2' : 'Mode 1'}
                      </span>
                    </button>
                  </li>
                </>
              )}

              {isLoggedIn ? (
                <li>
                  <button className="nav-item logout-btn" onClick={handleLogout}>
                    <div className="nav-icon">
                      <img src="/icons/logout.svg" alt="Se déconnecter" />
                    </div>
                    <span className="nav-text">Se déconnecter</span>
                  </button>
                </li>
              ) : (
                <>
                  {/* Se connecter => rose #d81b60 */}
                  <li>
                    <Link to="/login" className="nav-item pink-btn">
                      <div className="nav-icon">
                        <img src="/icons/login.svg" alt="Se connecter" />
                      </div>
                      <span className="nav-text">Se connecter</span>
                    </Link>
                  </li>
                  {/* Créer un compte => rose #d81b60 */}
                  <li>
                    <Link to="/signup" className="nav-item pink-btn">
                      <div className="nav-icon">
                        <img src="/icons/signup.svg" alt="Créer un compte" />
                      </div>
                      <span className="nav-text">Créer un compte</span>
                    </Link>
                  </li>
                </>
              )}
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
}

export default Header;
