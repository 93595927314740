// src/pages/HomeFeed.js

import React, { useState, useEffect, useRef } from 'react';
import './HomeFeed.css';
import { logEvent } from '../analytics'; // Importer logEvent

function HomeFeed({ isLoggedIn }) {
  const [videos, setVideos] = useState([]);
  const videoRefs = useRef([]);
  const [showInfo, setShowInfo] = useState([]);

  useEffect(() => {
    fetchVideos();
  }, []);

  const fetchVideos = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/videos/public`);
      const data = await response.json();
      setVideos(data);
      setShowInfo(new Array(data.length).fill(true));
    } catch (error) {
      console.error('Erreur lors du chargement des vidéos:', error);
    }
  };

  const handleScroll = () => {
    const scrollPosition = window.pageYOffset + window.innerHeight / 2;

    videoRefs.current.forEach((videoRef, index) => {
      if (videoRef) {
        const rect = videoRef.getBoundingClientRect();
        const videoTop = rect.top + window.pageYOffset;
        const videoHeight = rect.height;
        const videoCenter = videoTop + videoHeight / 2;

        if (Math.abs(scrollPosition - videoCenter) < videoHeight / 2) {
          if (videoRef.paused) {
            videoRef.play();
          }
        } else {
          if (!videoRef.paused) {
            videoRef.pause();
            videoRef.currentTime = 0;
          }
        }
      }
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    handleScroll();
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const toggleShowInfo = (index) => {
    logEvent('Bouton', 'Clic', showInfo[index] ? 'Masquer les infos' : 'Afficher les infos'); // Suivi du clic
    setShowInfo((prevShowInfo) => {
      const newShowInfo = [...prevShowInfo];
      newShowInfo[index] = !newShowInfo[index];
      return newShowInfo;
    });
  };

  // Handlers pour le suivi des vidéos
  const handleVideoPlay = (index) => {
    const videoTitle = videos[index]?.title || `Vidéo ${index + 1}`;
    logEvent('Vidéo', 'Lecture', videoTitle);
  };

  const handleVideoPause = (index) => {
    const videoTitle = videos[index]?.title || `Vidéo ${index + 1}`;
    logEvent('Vidéo', 'Pause', videoTitle);
  };

  const handleVideoEnded = (index) => {
    const videoTitle = videos[index]?.title || `Vidéo ${index + 1}`;
    logEvent('Vidéo', 'Terminée', videoTitle);
  };

  return (
    <div className="video-feed">
      {videos.map((video, index) => (
        <div key={video.id} className="video-container">
          <div className="video-wrapper">
            <video
              ref={(el) => (videoRefs.current[index] = el)}
              src={`${process.env.REACT_APP_API_URL}${video.url}`}
              className="video-player"
              controls
              muted
              loop
              playsInline
              onPlay={() => handleVideoPlay(index)}
              onPause={() => handleVideoPause(index)}
              onEnded={() => handleVideoEnded(index)}
            />
            {showInfo[index] ? (
              <div className="video-info">
                <h2>{video.title}</h2>
                <p>{video.description}</p>
                <button
                  className="toggle-info-btn"
                  onClick={() => toggleShowInfo(index)}
                >
                  Masquer les infos
                </button>
              </div>
            ) : (
              <button
                className="toggle-info-btn-hidden"
                onClick={() => toggleShowInfo(index)}
              >
                Afficher les infos
              </button>
            )}
          </div>
        </div>
      ))}
    </div>
  );
}

export default HomeFeed;
