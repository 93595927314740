// src/pages/VideoManager.js

import React, { useState, useEffect } from 'react';
import './VideoManager.css';

function VideoManager() {
  const [videos, setVideos] = useState([]);
  const [categories, setCategories] = useState([]);
  const [showCategoryInput, setShowCategoryInput] = useState(false);
  const [newCategoryName, setNewCategoryName] = useState('');

  useEffect(() => {
    fetchVideos();
    fetchCategories();
  }, []);

  const fetchVideos = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/videos`, {
        credentials: 'include',
      });
      const data = await response.json();
      setVideos(data);
    } catch (error) {
      console.error('Erreur lors du chargement des vidéos:', error);
    }
  };

  const fetchCategories = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/categories`, {
        credentials: 'include',
      });
      const data = await response.json();
      setCategories(data);
    } catch (error) {
      console.error('Erreur lors du chargement des catégories:', error);
    }
  };

  const handleUpload = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/videos`, {
        method: 'POST',
        credentials: 'include',
        body: formData,
      });

      if (response.ok) {
        const newVideo = await response.json();
        setVideos([newVideo, ...videos]);
        e.target.reset();
      } else {
        console.error("Erreur lors de l'upload de la vidéo");
      }
    } catch (error) {
      console.error("Erreur lors de l'upload de la vidéo:", error);
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm('Voulez-vous vraiment supprimer cette vidéo ?')) {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/videos/${id}`, {
          method: 'DELETE',
          credentials: 'include',
        });

        if (response.ok) {
          setVideos(videos.filter((video) => video.id !== id));
        } else {
          console.error('Erreur lors de la suppression de la vidéo');
        }
      } catch (error) {
        console.error('Erreur lors de la suppression de la vidéo:', error);
      }
    }
  };

  const handlePublishedChange = async (videoId, published) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/videos/${videoId}/published`, {
        method: 'PUT',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ published }),
      });

      if (response.ok) {
        const updatedVideo = await response.json();
        setVideos(videos.map((video) => (video.id === updatedVideo.id ? updatedVideo : video)));
      } else {
        console.error('Erreur lors de la mise à jour du statut de publication');
      }
    } catch (error) {
      console.error('Erreur lors de la mise à jour du statut de publication:', error);
    }
  };

  const handleAddCategory = async () => {
    if (!newCategoryName) {
      alert('Veuillez entrer un nom de catégorie');
      return;
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/categories`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ name: newCategoryName }),
      });

      if (response.ok) {
        const newCategory = await response.json();
        setCategories([...categories, newCategory]);
        setShowCategoryInput(false);
        setNewCategoryName('');
      } else {
        const errorData = await response.json();
        alert(errorData.message);
      }
    } catch (error) {
      console.error('Erreur lors de l\'ajout de la catégorie:', error);
    }
  };

  return (
    <div className="video-manager">
      <h1>Gestion des vidéos</h1>

      <div className="video-manager-content">
        <div className="upload-form">
          <h2>Uploader une nouvelle vidéo</h2>
          <form onSubmit={handleUpload}>
            <label>
              Titre :
              <input type="text" name="title" required />
            </label>
            <label>
              Description :
              <textarea name="description" />
            </label>
            <label>
              Vidéo :
              <input type="file" name="video" accept="video/*" required />
            </label>
            <label>
              Miniature :
              <input type="file" name="thumbnail" accept="image/*" />
            </label>
            <label>
              Publiée :
              <input type="checkbox" name="published" />
            </label>
            <label>
              Catégorie :
              <div className="category-select">
                <select name="category">
                  <option value="">Sélectionnez une catégorie</option>
                  {categories.map((category) => (
                    <option key={category.id} value={category.name}>
                      {category.name}
                    </option>
                  ))}
                </select>
                <button
                  type="button"
                  onClick={() => setShowCategoryInput(!showCategoryInput)}
                  className="add-category-button"
                >
                  {showCategoryInput ? 'Annuler' : 'Ajouter une catégorie'}
                </button>
              </div>
            </label>
            {showCategoryInput && (
              <label>
                Nouvelle catégorie :
                <input
                  type="text"
                  value={newCategoryName}
                  onChange={(e) => setNewCategoryName(e.target.value)}
                />
                <button type="button" onClick={handleAddCategory}>
                  Ajouter
                </button>
              </label>
            )}
            <button type="submit">Uploader</button>
          </form>
        </div>

        <div className="videos-list">
          <h2>Liste des vidéos</h2>
          <table>
            <thead>
              <tr>
                <th>Miniature</th>
                <th>Titre</th>
                <th>Catégorie</th>
                <th>Description</th>
                <th>Publiée</th>
                <th>Date de publication</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {videos.map((video) => (
                <tr key={video.id}>
                  <td>
                    {video.thumbnail ? (
                      <img
                        src={`${process.env.REACT_APP_API_URL}${video.thumbnail}`}
                        alt={`Miniature de ${video.title}`}
                        className="video-thumbnail"
                      />
                    ) : (
                      'Pas de miniature'
                    )}
                  </td>
                  <td>{video.title}</td>
                  <td>{video.category || 'Non spécifiée'}</td>
                  <td>{video.description}</td>
                  <td>
                    <input
                      type="checkbox"
                      checked={video.published}
                      onChange={(e) => handlePublishedChange(video.id, e.target.checked)}
                    />
                  </td>
                  <td>{new Date(video.created_at).toLocaleDateString()}</td>
                  <td>
                    <button onClick={() => handleDelete(video.id)}>Supprimer</button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default VideoManager;
