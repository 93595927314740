// src/pages/HomeSimple.js

import React, { useState, useEffect, useRef } from 'react';
import './Home.css';
import { logEvent } from '../analytics';

function HomeSimple({ isLoggedIn }) {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [fadeIn, setFadeIn] = useState(true);
  const [images, setImages] = useState([]);
  const [showMessage, setShowMessage] = useState(false);
  const [allImagesLoaded, setAllImagesLoaded] = useState(false); // Nouvel état pour savoir si toutes les images sont préchargées
  const intervalRef = useRef(null);

  const handleClick = (e) => {
    e.preventDefault();
    logEvent('Bouton', 'Clic', 'Voir les vidéos');
    setShowMessage(true);
  };

  const handleSubscribe = (e) => {
    e.preventDefault();
    logEvent('Bouton', 'Clic', "S'abonner");
    window.location.href = '/signup';
  };

  const closeModal = () => {
    logEvent('Modal', 'Fermeture', 'Inscription');
    setShowMessage(false);
  };

  useEffect(() => {
    const importAll = (r) => r.keys().map(r);
    const loadedImages = importAll(
      require.context('../assets/images/carousel', false, /\.(png|jpe?g|svg)$/)
    );

    // Précharger toutes les images
    let loadedCount = 0;
    loadedImages.forEach((src) => {
      const img = new Image();
      img.src = src;
      img.onload = () => {
        loadedCount++;
        if (loadedCount === loadedImages.length) {
          // Toutes les images sont chargées
          setImages(loadedImages);
          setAllImagesLoaded(true);
        }
      };
    });
  }, []);

  useEffect(() => {
    if (allImagesLoaded && images.length > 0) {
      // Une fois toutes les images chargées, on peut lancer le carrousel
      intervalRef.current = setInterval(() => {
        setFadeIn(false);
        setTimeout(() => {
          setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);

          setTimeout(() => {
            setFadeIn(true);
          }, 50);
        }, 700);
      }, 6000);

      return () => clearInterval(intervalRef.current);
    }
  }, [allImagesLoaded, images]);

  const handleVideoPlay = () => {
    logEvent('Vidéo', 'Lecture', 'Vidéo à la une');
  };

  const handleVideoPause = () => {
    logEvent('Vidéo', 'Pause', 'Vidéo à la une');
  };

  const handleVideoEnded = () => {
    logEvent('Vidéo', 'Terminée', 'Vidéo à la une');
  };

  return (
    <div className="home-container">
      {!isLoggedIn && (
        <div className="adult-warning">
          Ce site est réservé à un public majeur.
        </div>
      )}

      <section className="videos-highlight">
        <div className="video">
          <h3 className="censored">Vidéo à la une</h3>
          <div className="media-wrapper">
            <video
              loop
              muted
              autoPlay
              controls
              className="media-element"
              playsInline
              onPlay={handleVideoPlay}
              onPause={handleVideoPause}
              onEnded={handleVideoEnded}
            >
              <source src="/test.mp4" type="video/mp4" />
              Votre navigateur ne supporte pas la lecture vidéo.
            </video>
          </div>
        </div>

        <div className="video">
          <h3 className="uncensored">Vidéos non censurées !</h3>
          <div className="media-wrapper">
            {/* On n'affiche l'image du carrousel que si allImagesLoaded est true */}
            {allImagesLoaded && images.length > 0 && (
              <img
                key={currentImageIndex}
                src={images[currentImageIndex]}
                alt="Carousel"
                className={`media-element thumbnail ${fadeIn ? 'fade-in' : 'fade-out'}`}
              />
            )}
            <div className="overlay">
              <a href="#purchase" className="cta" onClick={handleClick}>
                Voir les vidéos
              </a>
            </div>
          </div>

          {showMessage && (
            <div className="modal">
              <div className="modal-content">
                <span className="close" onClick={closeModal}>
                  &times;
                </span>
                <h2>🚧 Cette section sera bientôt disponible !</h2>
                <p>
                  Merci de votre intérêt pour notre site tout neuf 🌟. Cette
                  section est en cours de création et sera disponible très
                  prochainement.
                </p>
                {!isLoggedIn && (
                  <>
                    <p>
                      En attendant, nous vous invitons à vous abonner 🔔 pour
                      être informé des nouveautés dès leur mise en ligne.
                    </p>
                    <button className="subscribe-btn" onClick={handleSubscribe}>
                      S'abonner
                    </button>
                  </>
                )}
                {isLoggedIn && (
                  <button className="subscribe-btn" onClick={closeModal}>
                    Ok
                  </button>
                )}
              </div>
            </div>
          )}
        </div>
      </section>
    </div>
  );
}

export default HomeSimple;
