// src/pages/Home.js
import React, { useContext } from 'react';
import { SiteModeContext } from '../contexts/SiteModeContext';
import HomeSimple from './HomeSimple';
import HomeFeed from './HomeFeed';

function Home({ isLoggedIn }) {
  const { siteMode } = useContext(SiteModeContext);

  return (
    <div>
      {siteMode === 'mode2' ? (
        <HomeFeed isLoggedIn={isLoggedIn} />
      ) : (
        <HomeSimple isLoggedIn={isLoggedIn} />
      )}
    </div>
  );
}

export default Home;
